<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <OrganizationNav />
      </div>
      <div class="col-md-9">
        <h5>Subscription</h5>
        <div class="card border-0 shadow-sm mb-4">
          <div class="card-body">
            <div
              v-if="showResumeAlert"
              class="alert alert-warning resume-alert mb-0 col-12"
            >
              <p>
                You have canceled your
                <strong v-if="activePlan">{{ activePlan.name }}</strong>
                subscription.
              </p>
              <p>
                The benefits of your subscription will continue until your
                current billing period expires on
                <strong v-if="hasSubscription">{{
                  data.subscription.ends_at | formatDate
                }}</strong
                >. You can resume your subscription at no additional cost until
                the end of the billing period.
              </p>
              <button
                class="btn btn-primary"
                :disabled="loading"
                @click="resumeSubscription()"
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                Resume your subscription
              </button>
            </div>

            <div v-if="!hasSubscription" class="col-12 mb-6">
              <pricing-table :data="data" />
            </div>
            <div class="col-12">
              <h3 class="h3">Current usage</h3>
              <AutomationUsage />
            </div>
          </div>
        </div>
        <button
          v-if="showCancelButton"
          class="btn btn-danger btn-sm"
          @click="cancelSubscription()"
        >
          Cancel subscription
        </button>
      </div>
      <div class="col-md-4">
        <div v-if="hasSubscription">
          <h5>Payment method</h5>
          <div class="card border-0 shadow-sm mb-4">
            <div class="card-body">
              <div v-if="data.method" class="border rounded p-3">
                <div class="row">
                  <div class="col-12">
                    <p class="mb-0">
                      <credit-card :brand="data.method.brand" />
                      <span class="ml-3"
                        >&bull;&bull;&bull; &bull;&bull;&bull;
                        &bull;&bull;&bull; {{ data.method.last_four }}</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-center">
              <router-link :to="{ name: 'organization.subscription.update' }">
                Update payment method
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import isEmpty from "lodash.isempty";
import find from "lodash.find";
import jsonAPI from "@/api";
import CreditCard from "@/components/Svg/CreditCard.vue";
import Default from "@/layouts/default.vue";
import PricingTable from "@/components/Account/PricingTable.vue";
import AutomationUsage from "@/components/Account/AutomationUsage.vue";
import OrganizationNav from "@/components/Nav/OrganizationNav.vue";

export default {
  components: {
    CreditCard,
    Default,
    PricingTable,
    AutomationUsage,
    OrganizationNav,
  },
  beforeRouteEnter(to, from, next) {
    jsonAPI.get("/organization/subscription").then((response) => {
      next((vm) => (vm.data = response.data));
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      data: {
        method: {
          brand: null,
          last_four: null,
        },
        subscription: {},
        plans: [],
      },
    };
  },
  computed: {
    hasSubscription() {
      return !isEmpty(this.data.subscription);
    },
    hasActiveSubscription() {
      return this.hasSubscription && !this.subscriptionIsExpired;
    },
    subscriptionIsExpired() {
      if (!this.hasSubscription) {
        return false;
      }
      return (
        this.data.subscription.cancelled &&
        !this.data.subscription.on_grace_period
      );
    },
    showResumeAlert() {
      if (!this.hasSubscription) {
        return false;
      }
      return (
        this.data.subscription.cancelled &&
        this.data.subscription.on_grace_period
      );
    },
    showActivePlan() {
      if (!this.hasSubscription && this.activePlan) {
        return true;
      }
      return !this.data.subscription.cancelled || this.subscriptionIsExpired;
    },
    activePlan() {
      if (this.hasSubscription && this.data.plans.length) {
        return find(this.data.plans, {
          gateway_id: this.data.subscription.stripe_plan,
        });
      }
      return null;
    },
    showCancelButton() {
      if (!this.hasSubscription) {
        return false;
      }
      return !this.data.subscription.cancelled;
    },
  },
  methods: {
    getSubscription: function() {
      jsonAPI
        .get("/organization/subscription")
        .then((response) => {
          this.data = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    cancelSubscription: function(e) {
      if (!confirm("Are you sure you want to cancel your subscription?")) {
        e.preventDefault();
      } else {
        this.loading = true;
        jsonAPI
          .post("/organization/subscription/cancel", this.form)
          .then(() => {
            this.getSubscription();
            this.loading = false;
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
            this.loading = false;
          });
      }
    },
    resumeSubscription: function() {
      this.loading = true;
      jsonAPI
        .post("/organization/subscription/resume", this.form)
        .then(() => {
          this.getSubscription();
          this.loading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
